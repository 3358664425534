<template>
  <b-collapse id="FiltrosFluxos">
    <div class="d-flex align-items-center row">
      <div class="col-4 w-100">
        <label>Título</label>
        <span class="p-input-icon-left w-100">
          <i class="pi pi-search" />
          <InputText
            placeholder="Digite o título"
            type="text"
            class="w-100"
            v-model="filtros_chatbot.titulo"
            @input="filtrarFluxos"
          />
        </span>
        <!-- <InputText
          type="text"
          class="w-100"
          v-model="filtros_chatbot.titulo"
          @input="filtrarFluxos"
        /> -->
      </div>
      <div class="col-4 w-100">
        <label>Categoria</label>
        <MultiSelect
          v-model="filtros_chatbot.categorias"
          :options="categorias"
          emptyFilterMessage="Nenhum resultado"
          :filter="true"
          placeholder="Filtrar por categoria"
          class="w-100"
          @input="filtrarFluxos"
          optionLabel="name"
        ></MultiSelect>
      </div>
    </div>
  </b-collapse>
</template>

<script>
import InputText from "primevue/inputtext";
import MultiSelect from "primevue/multiselect";
import { mapGetters } from "vuex";

export default {
  name: "FiltrosCampanha",
  components: {
    MultiSelect,
    InputText
  },
  props: {
    categorias: {
      type: Array,
      default: () => []
    },
    filtros_chatbot: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo", "layoutConfig"])
  },
  data() {
    return {};
  },
  methods: {
    filtrarFluxos() {
      this.$emit("filtrarFluxos");
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
#FiltrosFluxos {
  padding: 0.5rem 1.5rem;
}
</style>
