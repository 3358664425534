<template>
  <div>
    <Toast position="top-right" />

    <div class="card">
      <div class="card-header" style="padding: 0 !important">
        <div class="card-toolbar">
          <div
            class="d-flex align-items-end justify-content-end h-100 w-100 pr-5"
          >
            <div
              class="d-flex align-items-center py-5"
              style="justify-content: flex-end"
            >
              <b-button
                variant="outline-secondary"
                class="b-button-custom mr-2"
                v-b-toggle.FiltrosFluxos
                @click="filtros_ativos = !filtros_ativos"
              >
                <i class="fas fa-filter"></i> Filtros
              </b-button>
              <b-button
                v-if="isAdmin || hasPermission('gerenciar-campanha')"
                variant="success"
                class="b-button-custom"
                @click="clickBotaoCadastro"
              >
                {{ labelBotao[navSelecionada] }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body p-0 px-4">
        <FiltrosChatbot
          :categorias="categorias_chatbot_full_object"
          :filtros_chatbot="filtros_chatbot"
          @filtrarFluxos="filtrarFluxos"
        />
        <div class="aut-area-exibir">
          <IndexFluxo
            class="aut-camp-area"
            v-if="navSelecionada == 0"
            ref="IndexFluxos"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import FiltrosChatbot from "./FiltrosChatbot.vue";
import ApiService from "../../../../core/services/api.service";
import API_LINKS from "../automacao/api.links";

export default {
  components: {
    Toast: () => import("primevue/toast"),
    IndexFluxo: () => import("./Index.vue"),
    FiltrosChatbot
  },
  data() {
    return {
      filtros_chatbot: {
        categorias: [],
        titulo: ""
      },
      categorias_chatbot_full_object: [],
      categorias_chatbot: [],
      filtros_ativos: false,
      menusAtivos: true,
      menusChatbot: [],
      navSelecionada: 0,
      showOverlay: true,
      campanhas: [],
      labelBotao: ["Novo Fluxo"],
      aba: ["fluxo"]
    };
  },
  methods: {
    filtrarFluxos() {
      this.$refs.IndexFluxos.filtrarFluxos(this.filtros_chatbot);
    },
    mudarNav(nav) {
      this.navSelecionada = nav;
    },
    clickBotaoCadastro() {
      this.$router.push({
        path: "/painel/automacao/cadastro-fluxo"
      });
    },
    async getCategories() {
      const res = await ApiService.get(API_LINKS.chatbot_fluxo_categoria);
      this.categorias_chatbot_full_object = res.data.categorias.map(
        (categoria) => {
          return {
            ...categoria,
            name: categoria.fla_etiqueta,
            code: categoria.fluxo_categoria_id
          };
        }
      );

      const curr_categories = JSON.parse(
        JSON.stringify(this.categorias_chatbot_full_object)
      );

      const visited_categories = {};
      for (let i = 0; i < curr_categories.length; i++) {
        const categoria = curr_categories[i];
        if (visited_categories[categoria.name]) {
          visited_categories[categoria.name] =
            visited_categories[categoria.name] + 1;
          curr_categories[i].name = `${categoria.name} (${
            visited_categories[categoria.name]
          })`;
        } else {
          visited_categories[categoria.name] = 1;
        }
      }

      this.categorias_chatbot_full_object = curr_categories;

      return curr_categories;
    }
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Chatbot"
      },
      {
        title: "Fluxos",
        route: "/painel/chatbot"
      }
    ]);

    if (
      this.$route.params.toast != "undefined" &&
      this.$route.params.toast != null
    ) {
      setTimeout(() => {
        this.$toast.removeAllGroups();
        this.$toast.add(this.$route.params.toast);
      }, 2000);
    }
    await this.getCategories();
  },
  computed: {
    ...mapGetters(["isAdmin", "hasPermission"])
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300&display=swap");

.b-button-custom {
  width: 142px;
}

.aut-body {
  border-radius: 5px;
  background-color: #fff;
}

.aut-area-exibir {
  display: flex;
  height: 100%;
  width: 100%;
}

.aut-header {
  height: 70px;
  width: 100%;
}

.aut-camp-area {
  width: 100%;
  min-height: 500px;
}

.container-dashboard {
  border-radius: 15px;
  background-color: #fff;
  padding: 20px 10px 20px 10px;
  margin-bottom: 15px;
}

.campo-estatistica {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.dropdown-form {
  padding: 3px 0px;
  width: 170px;
  height: 45px;
}

.dropdown-form:hover {
  background-color: rgb(247, 247, 247);
}

.button-container {
  border: 1px solid #e4e6ef;
  outline: none;
  color: #e3e4ed;
  background-color: transparent;
  border-radius: 0.42rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 38px;
}
.button-container.btn i {
  padding: 0 !important;
}
</style>
